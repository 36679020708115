@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    padding: 0;
    margin: 0;
  }

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading='lazy'] {
      clip-path: inset(0.6px);
    }
  }

  html {
    @apply !scroll-smooth;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
  }

  body {
    @apply flex min-h-screen flex-col bg-white text-gray-500 antialiased;
    overscroll-behavior: none;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
  }

  a,
  button,
  [role='button'] {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer components {
  .container {
    @apply mx-auto w-full max-w-screen-xl px-5 lg:px-10 2xl:px-20;
  }

  .container-fluid {
    @apply mx-auto w-full px-5 lg:px-10 2xl:px-20;
  }
}
